<template>
    <div class="content">
        <div class="title">在错误中进阶</div>
        <div class="small_title">显性资源与隐性资源 / 本体资源与延伸资源 / 单一媒体资源与融媒体资源</div>
        <div class="contentDetail">
            <div class="detail1">
              <div class="title1">自主学习</div>
              <p class="content1">
                通过与高中课标提出的18个学习任务群关联的情景任务学习，启发学生思考，培养学生语文核心素养养成。
              </p>
            </div>
            <div class="detail2">
              <div class="title2">陪伴助学</div>
              <p class="content2">
                将学习任务拆解为若干学习活动，围绕学习活动提供学习助手和学习资源，包括讨论互动、地图定位、优秀作品、在线问答、划线评论等，充分发挥陪伴助读和交流协作理念；<br />学习资源供学生自主学习或小组探究，包括相关阅读、图文注释、名师微课、名家诵读；相关阅读既包括一线优秀语文教师对文章的讲解注释，也包括名家对课文的深度解读和赏析，供学生参考，同时融合读写一体的理念对学生进行写作指导，辅助学生更好地完成写作任务。
              </p>
            </div>
            <div class="detail3">
              <div class="title3">综合评价</div>
              <p class="content3">
                通过答题自测环节进行知识的巩固迁移，进一步融合学习进阶理念。<br />通过习题设置，以实现学生基础能力、拓展提升、深度思考三个层级目标，结合学生的答题情况、阅读行为轨迹进行综合性评价，促使学生语文素养的全面提升。
              </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'student',
    data () {
        return {

        }
    }
}
</script>

<style scoped lang="less">
    .content{
      width: 1140px;
      height: 600px;
      margin: auto;
      margin-top: 20px;
      color: #000000;
      .title{
        padding-top: 40px;
        font-size: 32px;
        font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
        font-weight: bold;
        line-height: 60px;
        letter-spacing: 20px;
        text-align: center;
      }
      .small_title{
       line-height: 30px;
       text-indent: -20px;
       text-align: center;
      }
      .contentDetail{
        display: flex;
        flex-wrap: wrap;
        
        .detail1{
          width: 367px;
          height: 400px;
          background: url('../../assets/img/25.png');
          background-size: 100% 100%;
          margin-top: 20px;
          box-shadow: 0px 2px 10px 0px rgba(219, 206, 188, 0.5);
          padding: 40px 40px 20px;
          color: #000;
            .title1{
              text-align: center;
              font-size: 20px;
              line-height: 34px;
              font-weight: bold;
            }
            .content1{
              font-size: 14px;
              line-height: 26px;
            }
            &:hover{
             transform:scale(1.02);
            }
        }
        .detail2{
          width: 367px;
          height: 400px;
          background: url('../../assets/img/26.png');
          background-size: 100% 100%;
          margin-top: 20px;
          box-shadow: 0px 2px 10px 0px rgba(219, 206, 188, 0.5);
          margin-left: 19.5px;
          padding: 40px 40px 20px;
          .title2{
            text-align: center;
            font-size: 20px;
            line-height: 34px;
            font-weight: bold;
          }
          .content2{
            font-size: 14px;
            line-height: 26px;
          }
          &:hover{
             transform:scale(1.02);
          }
        }
        .detail3{
          width: 367px;
          height: 400px;
          background: url('../../assets/img/27.png');
          background-size: 100% 100%;
          margin-top: 20px;
          box-shadow: 0px 2px 10px 0px rgba(219, 206, 188, 0.5);
          margin-left: 19.5px;
          padding: 40px 40px 20px;
          .title3{
            text-align: center;
            font-size: 20px;
            line-height: 34px;
            font-weight: bold;
          }
          .content3{
            font-size: 14px;
            line-height: 26px;
          }
          &:hover{
            transform:scale(1.02);
          }
        }
      }
    }
</style>